import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import styles from '../css/single-overview.module.css'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const OverviewTemplate = ({ data }) => {
    const { titel, datum, korteBeschrijving, overzicht: { file: { url } }} = data.contentfulFinancieel;

    return (
        <Layout>
            <section className={styles.overview}>
                <div className={styles.center}>
                    <h1>{titel}</h1>
                    <h4>Datum: {datum}</h4>
                    <article className={styles.post}>
                        {
                            <>
                            <div className={styles.desc}>{korteBeschrijving}</div>
                            <a className={styles.download} href={url}>Download</a>
                            </>
                        }
                    </article>
                    <AniLink fade to="/financieel" className="btn-primary">Terug</AniLink>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query getOverview($slug: String) {
        contentfulFinancieel(slug: { eq: $slug }) {
            titel
            datum(formatString: "DD-MM-YYYY")
            korteBeschrijving
            overzicht {
                file {
                    url
                }
            }

        }
    }
`
export default OverviewTemplate


